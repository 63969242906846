var slides = [
    {
        'class' : 'home_masthead_1',
        'title' : '360-Degree Security for Commercial Properties',
        'subtitle' : 'With comprehensive security and fire protection systems, planning and consulting, Integrated Protection Services is a trusted partner to commercial property tenants, owners and managers.',
        'background' : '/images/building.jpg'
    },
    {
        'class' : 'home_masthead_2',
        'title' : 'Customized Protection Solutions As Unique as Your Business',
        'subtitle' : 'Benefit from our experience customizing security solutions for diverse markets. Let Integrated Protection Services evaluate your business and develop a strategy for your needs.',
        'background' : '/images/blueprints.jpg'
    },
    {
        'class' : 'home_masthead_3',
        'title' : 'Secure, Satisfied Customers',
        'subtitle' : 'Clients stick with Integrated Protection Services because we stick with them well after the consulting and installation is done. Now that’s what we call security.',
        'background' : '/images/handshake.jpg'
    },
];

var num = 0;
function slideshow(){
    $('.home_masthead_cont').prepend(`
    <div class="home_masthead ${slides[num].class}">
        <div class="container">
            <div class="home_text_box">
                <h1 class="masthead_title">${slides[num].title}</h1>
                <p class="masthead_subtitle">${slides[num].subtitle}</p>
            </div>
        </div>
    </div>
    `);

    $(`.bullet`).removeClass('active');
    $(`.bullet:nth-child(${num + 1})`).addClass('active');
    $(`.${slides[num].class}`).fadeIn(50);
    $(`.${slides[num].class} .container .home_text_box .masthead_title`).delay(1000).toggle("drop", {direction: "up"});
    $(`.${slides[num].class} .container .home_text_box .masthead_subtitle`).delay(2000).toggle("drop", {direction: "left"});
    $(`.${slides[num].class} .container .home_text_box`).delay(2200).animate({backgroundColor: "#0d213c80"}, 500);

    if(num < slides.length - 1){
        $(`.${slides[num].class}`).delay(8000).fadeOut(50, function(){
            $(this).remove();
        })
        num++;
    } else{
        $(`.${slides[num].class}`).delay(8000).fadeOut(50, function(){
            $(this).remove();
        })
        num = 0;
    
    }
}

slideshow();
var start = setInterval(slideshow, 8050)

$('.bullet').click(function(){
    clearInterval(start);
    $('.home_masthead').remove();
   

    num = parseInt($(this).attr("class").split(" ")[1].slice(-1)) - 1;
    slideshow();
    start = setInterval(slideshow, 8050)
})

$('.prev').click(function(){
    clearInterval(start);
    $('.home_masthead').remove();
   
    switch(num) {
        case 0:
          num = 1;
          break;
        case 1:
          num = 2;
          break;
        case 2:
            num = 0;
            break;
        default:
          break;
    }

    slideshow();
    start = setInterval(slideshow, 8050)
})
$('.next').click(function(){
    clearInterval(start);
    $('.home_masthead').remove();

    slideshow();
    start = setInterval(slideshow, 8050)
})

$('.home_masthead_cont').hover(
    function(){
        $('.arrows').css("display", "flex").hide().fadeIn(500);
        $('.bullets_cont').css("display", "flex").hide().fadeIn(500);
    },
    function(){
        $('.arrows').fadeOut(500);
        $('.bullets_cont').fadeOut(500);
    }
)
